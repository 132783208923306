import { MouseEventHandler } from 'react';
import ButtonLabel, { VARIANT } from '../ButtonLabel';
import CaretDownIcon from '../icons/CaretDownIcon';
interface Props {
  variantLength: number;
  variantLabel: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isOutOfStock?: boolean;
}
const className = 'border rounded-lg border-neutral-20 py-2 px-3 w-full flex items-center justify-between gap-2';
const VariantLabel = ({
  variantLength,
  variantLabel,
  onClick,
  isOutOfStock
}: Props) => {
  const hasMoreThanOneVariant = variantLength > 1;
  const variantNameWithCaret = <>
      <ButtonLabel variant={VARIANT.TWO} label={variantLabel} className={`font-nato-semibold ${isOutOfStock ? 'text-[#80868C]' : 'text-primary-100'}`} />
      {hasMoreThanOneVariant ? <CaretDownIcon className={`fill-transparent ${isOutOfStock ? 'stroke-[#80868C]' : 'stroke-primary-100'}`} height={20} width={20} /> : null}
    </>;
  return <div className="h-[36px]" data-sentry-component="VariantLabel" data-sentry-source-file="VariantLabel.tsx">
      {variantLabel.length ? hasMoreThanOneVariant ? <button className={className} data-testid="variant-drawer-button" onClick={onClick}>
            {variantNameWithCaret}
          </button> : <div className={className} data-testid="variant-label">
            {variantNameWithCaret}
          </div> : null}
    </div>;
};
export default VariantLabel;
import { forwardRef, Ref, SVGAttributes } from 'react';
type Props = SVGAttributes<SVGSVGElement>;
const CurrentLocationIcon = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 24" ref={ref} {...props}>
      <path d="M12 2a8.26 8.26 0 0 0-8.25 8.25c0 7.06 7.5 12.39 7.82 12.614a.75.75 0 0 0 .86 0c.32-.223 7.82-5.555 7.82-12.614A8.26 8.26 0 0 0 12 2Zm0 5.25a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z" fill="url(#a)" />
      <defs>
        <linearGradient id="a" x1="3.75" y1="2" x2="25.039" y2="9.62" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFAD00" />
          <stop offset=".495" stopColor="#FE6601" />
          <stop offset="1" stopColor="#FF0E01" />
        </linearGradient>
      </defs>
    </svg>);
export default CurrentLocationIcon;
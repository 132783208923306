import useTranslation from 'next-translate/useTranslation';
import { DEFAULT_CURRENCY } from '@/constants/common';
import formatCurrency from '@/utils/helpers/formatCurrency';
import ButtonLabel, { VARIANT as BUTTON_VARIANT } from '../ButtonLabel';
import Caption, { VARIANT as CAPTION_VARIANT } from '../Caption';
interface Props {
  actualPrice: number;
  discountedPrice: number;
  showDiscountPercentage?: boolean;
}
const ProductPrice = ({
  actualPrice,
  discountedPrice,
  showDiscountPercentage = false
}: Props) => {
  const {
    t
  } = useTranslation('products');
  const discountPercent = Math.round((actualPrice - discountedPrice) / actualPrice * 100);
  return <div className="flex items-center mt-1" data-sentry-component="ProductPrice" data-sentry-source-file="ProductPrice.tsx">
      <ButtonLabel variant={BUTTON_VARIANT.ONE} className="font-nato-semibold text-neutral-80" label={`${DEFAULT_CURRENCY}${discountedPrice}`} data-sentry-element="ButtonLabel" data-sentry-source-file="ProductPrice.tsx" />
      {actualPrice !== discountedPrice ? <>
          <Caption variant={CAPTION_VARIANT.TWO} className="font-nato-medium text-neutral-50 ml-1 line-through" label={formatCurrency(actualPrice)} />
          {showDiscountPercentage ? <span className="ml-1 text-xs text-[#43A314]">
              ({discountPercent}% {t('sale_off')})
            </span> : null}
        </> : null}
    </div>;
};
export default ProductPrice;
import { Button, SIZE } from '@dehaat/dds';
import { DEFAULT_LANG } from '@dehaat/kisan-app-bl/constants/common';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useEffect, useState } from 'react';
import { CART_TYPE } from '@/constants/cart';
import { COOKIE_MAX_AGE_1YEAR, CURRENT_LOCATION_KEY, LAT_LNG_COOKIE_NAME, REDIRECT_URI_KEY } from '@/constants/common';
import { CartContext } from '@/context/CartProvider';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import locationIcon from '@/images/location/yourLocation.webp';
import { fetchHyperlocalData, selectCurrentLocation, selectCurrentVendor, selectCurrentVendorId, selectLoadingHyperlocalData, setCurrentLocation, setLoadingHyperlocalData } from '@/store/features/locationSlice';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { getCurrentLocationValues, getGeoLocationValues } from '@/utils/google';
import { getCookieValue, isDigiAcreUser, setLocalStorageKey } from '@/utils/helper';
import ButtonLabel, { VARIANT as BUTTONLABEL_VARIANT } from '../ButtonLabel';
import CaretDownIcon from '../icons/CaretDownIcon';
import CurrentLocationIcon from '../icons/CurrentLocationIcon';
import Spinner from '../Spinner';
interface Props {
  mainContainerClassName?: string;
  locationTextClassName?: string;
  allowNavigation?: boolean;
  fromScreen?: string;
  showAddressDetails?: boolean;
}
const CurrentLocation = ({
  mainContainerClassName,
  locationTextClassName,
  allowNavigation = false,
  fromScreen,
  showAddressDetails = true
}: Props) => {
  const {
    t
  } = useTranslation('common');
  const {
    cartType
  } = useContext(CartContext);
  const vendorId = useAppSelector(selectCurrentVendorId);
  const dispatch = useAppDispatch();
  const isHyperlocalDataLoading = useAppSelector(selectLoadingHyperlocalData);
  const currentLocation = useAppSelector(selectCurrentLocation);
  const currentVendor = useAppSelector(selectCurrentVendor);
  const [isLocationLoding, setIsLocationLoding] = useState(false);
  const {
    push,
    asPath,
    replace,
    locale,
    query: {
      v
    }
  } = useRouter();
  const url = fromScreen ? `/location?${REDIRECT_URI_KEY}=${fromScreen}` : '/location';
  const handleLocationChange = () => {
    if (fromScreen) {
      push(url);
    } else {
      if (asPath === '/dcs') {
        push('/location');
      } else if (asPath === '/ondc/dcs') {
        push('/ondc/location');
      } else {
        if (vendorId) {
          push(`${cartType === CART_TYPE.ONDC ? '/ondc' : ''}/dcs`);
        } else push(`${cartType === CART_TYPE.ONDC ? '/ondc' : ''}/location`);
      }
    }
  };
  const formattedAddress = currentLocation ? currentLocation.formattedAddress : '';
  const handleGetLocation = async () => {
    setIsLocationLoding(true);
    if (!currentLocation && !isHyperlocalDataLoading) {
      const location = await getCurrentLocationValues();
      if (location) {
        trackCustomEvent(CustomEventTypes.LOCATION_PERMISSION_GRANTED);
        const {
          latitude,
          longitude
        } = location;
        const address = await getGeoLocationValues(latitude, longitude);
        dispatch(setCurrentLocation(address));
        setLocalStorageKey(CURRENT_LOCATION_KEY, address);
        document.cookie = `${LAT_LNG_COOKIE_NAME}=${address.latLng}; max-age=${COOKIE_MAX_AGE_1YEAR}; path=/`;
        await dispatch(fetchHyperlocalData({
          latLng: address.latLng,
          language: locale || DEFAULT_LANG,
          vendorId: (v as string | undefined),
          isCSCUser: isDigiAcreUser()
        }));
        replace(asPath);
      } else {
        trackCustomEvent(CustomEventTypes.LOCATION_PERMISSION_DENIED);
        push(url);
      }
    }
    setIsLocationLoding(false);
  };

  // loadingHyperlocalData is by default `true` in redux
  // this helps in showing allow location bottom drawer when users lands on app for the 1st time
  // since we are only sshowing drawer when loadingHyperlocalData is `false`
  useEffect(() => {
    if (!getCookieValue(LAT_LNG_COOKIE_NAME)) dispatch(setLoadingHyperlocalData(false));
  }, [dispatch]);
  return <div className={mainContainerClassName} data-sentry-component="CurrentLocation" data-sentry-source-file="CurrentLocation.tsx">
      {showAddressDetails && <div className="flex items-center">
          <CurrentLocationIcon width={24} height={24} className="mr-2 " />
          <div>
            <p className="text-neutral-100 text-sm font-nato-semibold max-w-[170px] md:max-w-[460px] line-clamp-3">
              {currentVendor ? currentVendor?.company_name : t('your_addresses')}
            </p>
            {currentLocation && <button aria-label="Click here to change location or vendor" disabled={!allowNavigation} onClick={handleLocationChange} className={`flex py-1 items-center ${allowNavigation ? 'cursor-pointer' : ''}`}>
                <ButtonLabel variant={BUTTONLABEL_VARIANT.TWO} className={`font-medium text-xs text-neutral-90 max-w-[150px] md:max-w-[360px] truncate ${locationTextClassName}`} label={formattedAddress} />
                {allowNavigation && <CaretDownIcon className="h-5 w-5 stroke-neutral-90 fill-transparent" />}
              </button>}
          </div>
        </div>}
      {isLocationLoding && <Spinner />}
      {!currentLocation && !isHyperlocalDataLoading && <section data-testid="ask-location-popup" className="fixed top-0 left-0 h-full w-full" style={{
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 49
    }}>
          <div className="absolute w-full bottom-0 b-white px-4 py-6 flex flex-col items-center gap-3 bg-white">
            <Image height={96} width={96} src={locationIcon} alt={'location_icon_alt'} />
            <h5 className="text-neutral-90">
              {t('location:access_to_location')}
            </h5>
            <p className="text-neutral-90 text-center font-medium">
              {t('location:location_for_dehaat_center')}
            </p>
            <Button text={t('location:allow_location')} onClick={handleGetLocation} size={SIZE.large} buttonStyle={{
          marginTop: 8,
          width: '100%'
        }} />
          </div>
        </section>}
    </div>;
};
export default CurrentLocation;
interface Props {
  count: number;
}
const ProductCardLoading = ({
  count
}: Props) => <>
    {Array(count).fill(null).map((_, index) => <section className="w-full pt-4 px-4 pb-5 flex flex-col items-center border-x-transparent border-b-transparent border-t-neutral-10 border first:border-none animate-pulse" key={index}>
          <div className="shrink-0 grow-0 border border-neutral-10 rounded bg-neutral-30 w-20 h-20" />
          <div className="flex w-full flex-col items-left mt-4">
            <div className="mb-1 h-4 w-16 rounded bg-neutral-30" />
            <div className="rounded bg-neutral-30 h-4 w-full" />
            <div className="h-5 w-7 bg-neutral-30 rounded mt-2" />
            <div className="flex flex-col gap-3 items-center mt-8">
              <div className="rounded bg-neutral-30 h-4 w-full" />
              <div className="w-full rounded-lg bg-neutral-30 border-neutral-neutral-20 h-[38px]" />
            </div>
          </div>
        </section>)}
  </>;
export default ProductCardLoading;
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';
import { CART_TYPE } from '@/constants/cart';
import { CartContext } from '@/context/CartProvider';
import useIsMobile from '@/hooks/useIsMobile';
import { conversionLabels } from '@/utils/analytics/conversionLabels';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { trackCustomEvent, trackGAConversionEvent } from '@/utils/analytics/googleAnalytics';
import { getNumberOfItemsInCart } from '@/utils/cart';
import Paragraph, { VARIANT } from './Paragraph';
interface Props {
  className?: string;
}
const CheckoutPopup = ({
  className = 'bottom-0'
}: Props) => {
  const {
    t
  } = useTranslation('common');
  const {
    cartItems,
    currentVendorId,
    cartType
  } = useContext(CartContext);
  const noOfItemsInCart = getNumberOfItemsInCart(cartItems);
  const {
    pathname
  } = useRouter();
  const {
    isMobile,
    isWindowLoading
  } = useIsMobile();
  const onBuyNowClick = () => {
    if (typeof window !== 'undefined' && window.scq) {
      const utmParams = sessionStorage.getItem('utm_params');
      if (utmParams) {
        try {
          if (JSON.parse(utmParams).utm_source === 'sharechat') {
            window.scq('Initiate checkout', 'pre_defined');
          }
        } catch (error) {
          console.error('Failed to parse utm_params from sessionStorage:', error);
        }
      }
    }
    trackCustomEvent(CustomEventTypes.BUY_NOW);
    trackGAConversionEvent(CustomEventTypes.BUY_NOW, {}, conversionLabels.BUY_NOW);
  };
  if (!isMobile && !isWindowLoading && !pathname?.includes('/ondc')) {
    return null;
  }
  return <>
      {currentVendorId != null && noOfItemsInCart > 0 ? <div data-testid="checkout-popup" className={`fixed left-0 w-full h-[60px] bg-primary-80 z-40 text-white px-4 py-2 ${className}`}>
          <div className="h-full flex justify-between items-center max-w-7xl m-auto">
            <Paragraph variant={VARIANT.BIG}>{`${noOfItemsInCart} ${t(noOfItemsInCart > 1 ? 'items' : 'item')}`}</Paragraph>
            <Link data-testid="buy-now-link" href={`${cartType === CART_TYPE.ONDC ? '/ondc' : ''}/cart`} className="h-full bg-white text-primary-80 w-36 rounded-md flex justify-center items-center" onClick={onBuyNowClick}>
              {t('buy_now')}
            </Link>
          </div>
        </div> : null}
    </>;
};
export default CheckoutPopup;